






































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandQueryHistoryItem, BrandQueriesService, StoredBrandQueriesService, StoredBrandQuery, BrandQueryHistoryService, BrandQueryResult, BrandQueryExecutionOptions } from '@/api/braendz';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import StoredBrandQuerySlider from '@/components/StoredBrandQueries/StoredBrandQuerySlider.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import ConfigureStoredBrandQueryPopup from '@/components/StoredBrandQueries/ConfigureStoredBrandQueryPopup.vue';
import ExecuteStoredBrandQueryPopup from '@/components/StoredBrandQueries/ExecuteStoredBrandQueryPopup.vue';
import BrandQueryResultPopup from '@/components/BrandQueryResults/BrandQueryResultPopup.vue';


@Component({
    components: {
        StoredBrandQuerySlider,
        VueSlickCarousel,
        ConfirmationPopup,
        ConfigureStoredBrandQueryPopup,
        ExecuteStoredBrandQueryPopup,
        BrandQueryResultPopup
    }
})
export default class StoredBrandQueryCard extends Vue {

    // Members
    public deleteStoredBrandQueryConfirmationVisible = false;
    public configureStoredBrandQueryPopupVisible = false;
    public executeStoredBrandQueryPopupVisible = false;
    public lastBrandQueryResultPopupVisible = false;

    public brandQueryHistoryItems = new BusyList<BusyObject<BrandQueryHistoryItem>>();
    public lastBrandQueryResult = new BusyObject<BrandQueryResult>();
    
    // Properties
    @Prop({ required: true })
    public storedBrandQuery!: BusyObject<StoredBrandQuery>;

    @Prop({ required: false, default: 0, type: Number })
    public indent!: number;

    // Getter & Setter
    public get itemsToShow(): number {
        return Math.ceil(window.innerWidth / 550);
    }

    // Lifecycle Methods:
    public mounted() {
        this.refreshBrandQueryHistory();
    }

    // Methods:
    public async refreshBrandQueryHistory(): Promise<void> {
        await this.brandQueryHistoryItems.load(async () => {
            if(this.storedBrandQuery.object?.id) {
                const count = this.storedBrandQuery.object.agentExecutionFrequency === 'Daily' ? 30 : this.storedBrandQuery.object.agentExecutionFrequency === 'Weekly' ? 12 : this.storedBrandQuery.object.agentExecutionFrequency === 'Monthly' ? 3 : 30;
                return (await BrandQueryHistoryService.getBrandQueryHistory(this.storedBrandQuery.object?.id, count)).map(i => new BusyObject(i));
            }
            return null;
        });
    }

    public async deleteStoredBrandQuery(): Promise<void> {
        await this.storedBrandQuery.delete(async () => {
            if(this.storedBrandQuery.object?.id) {
                await StoredBrandQueriesService.deleteStoredBrandQuery(this.storedBrandQuery.object.id);
                return null;
            }
            return this.storedBrandQuery.object;
        });
    }

    public async executeStoredBrandQuery(options: BrandQueryExecutionOptions) {

        await this.lastBrandQueryResult.load(async () => {
            if(this.storedBrandQuery.object?.id) {
                return await BrandQueriesService.executeStoredBrandQuery(this.storedBrandQuery.object.id, options);
            }
            return null;
        });

        if(this.lastBrandQueryResult.object?.id) {

            const busyBrandQueryHistoryItem = new BusyObject<BrandQueryHistoryItem>();
            this.brandQueryHistoryItems.list.push(busyBrandQueryHistoryItem);
            await busyBrandQueryHistoryItem.load(async () => {
                if(this.lastBrandQueryResult.object?.id) {
                    return await BrandQueryHistoryService.getBrandQueryHistoryItem(this.lastBrandQueryResult.object.id);
                }
                return null;
            });
        }

        this.executeStoredBrandQueryPopupVisible = false;
        this.lastBrandQueryResultPopupVisible = true;
    }

    public fireStoredBrandQueryChanged(value: StoredBrandQuery): void {
        this.$emit('storedBrandQueryChanged', value);
    }
}
